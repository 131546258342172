import React from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';
import './JumbotronArtist.scss';

export const JumbotronArtist = () => {
  return (
    <Jumbotron
      className="jumbotronArtist"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="page-hero d-flex align-items-center justify-content-center ArtistContent">
        <div>
          <h1 className="artistTitle">
            Empieza tu camino de convertirte en fotógrafo profesional
          </h1>
          <div className="btnWrap">
            <Button
              onClick={() => {
                ScrollToSmooth(document.querySelector('#payment'));
              }}
              bsPrefix
              className="buttonArtist enrollArtist"
            >
              <p className="d-none d-lg-block">
                ¡Quiero convertirme en fotógrafo profesional!
              </p>
              <p className="d-block d-lg-none">
                ¡Quiero convertirme
                <br />
                en fotógrafo profesional!
              </p>
            </Button>
          </div>
        </div>
      </div>
    </Jumbotron>
  );
};

export default JumbotronArtist;
