import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Main from '../views/Main';
// import Maintenance from "../Maintenance";

import PoliticaDeCookies from '../views/pages/cookies/PoliticaDeCookies';
import PoliticaDePrivacidad from '../views/pages/privacity/PoliticaDePrivacidad';
import TerminosYCondiciones from '../views/pages/terms/TerminosYCondiciones';
import ThankPage from '../views/ThankPage';

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/gracias-compra" component={ThankPage} />
        {/* <Route exact path="/home" component={Main} /> */}

        <Route
          path="/politica-de-cookies"
          exact
          render={(props) => <PoliticaDeCookies {...props} />}
        />
        <Route
          path="/politica-de-privacidad"
          exact
          render={(props) => <PoliticaDePrivacidad {...props} />}
        />
        <Route
          path="/terminos-y-condiciones"
          exact
          render={(props) => <TerminosYCondiciones {...props} />}
        />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
