import React from 'react';
import { Row } from 'react-bootstrap';
import { isPromoActive } from '../../../../layout/Layout';
import PaymentCard from '../../components/paymentCard/PaymentCard';
import './PaymentSection.scss';

export const PaymentSection = () => {
  return (
    <Row className="RootPayment">
      <div className="RootPayment-inside" style={{ zIndex: '99' }} id="payment">
        <PaymentCard />
      </div>
    </Row>
  );
};

export default PaymentSection;
