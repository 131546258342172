import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './GridContent.scss';

export const gridContent = (props) => {
  return (
    <Container fluid>
      <Row className="gridRow">
        <Col
          className="gridCol col-12 col-lg-6"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="contentGrid">
            <div className="">
              <h1 className="titleSquare">
                <span className="textPrimary">La gran mayoría</span> de las
                personas que empiezan en la fotografía acaban abandonando.
              </h1>
            </div>
            <div className="hrLine"></div>
            <div className="textSquare">
              <p>
                Esto es debido a que no consiguen los resultados finales que
                desean. Pienso que la mejor forma de convertirse en un experto
                de la fotografía sin fallar en el intento es aprendiendo de
                alguien que ya lo es y que se dedica 100% a ello.
                <br />
                Sobre todo si quieres ahorrarte años de prueba y error.
              </p>
            </div>
          </div>
        </Col>
        <Col
          className="gridCol col-12 col-lg-6 gridImg"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="gridImg1"></div>
        </Col>
      </Row>
      {/* <Row className="gridRow gridRowResp d-flex d-lg-none">
        <Col
          className="gridCol col-12 col-lg-6"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="contentGrid">
            <div className="">
              <h1 className="titleSquare">
                <span className="textPrimary">No te unas a la mayoría. </span>
                Únete al pequeño grupo de artistas que exploran, crean, y
                mejoran lo estándar.
              </h1>
            </div>
            <div className="hrLine"></div>
            <div className="textSquare">
              <p>
                Me gusta pensar que la fotografía, más que un hobby o una forma
                de ganarse la vida, es más bien arte: una forma de expresarse,
                de capturar lo que sientes y lo que ves, de ir más allá de la
                experiencia sensorial.
              </p>
            </div>
          </div>
        </Col>
      </Row> */}
      {/* <Col
          className="gridCol col-12 col-lg-6 gridImg"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="gridImg2"></div>
        </Col> */}
      {/* <Col
          className="gridCol col-sm-6 d-none d-lg-flex"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="contentGrid">
            <div className="">
              <h1 className="titleSquare">
                <span className="textPrimary">No te unas a la mayoría. </span>
                Únete al pequeño grupo de artistas que exploran, crean, y
                mejoran lo estándar.
              </h1>
            </div>
            <div className="hrLine"></div>
            <div className="textSquare">
              <p>
                Me gusta pensar que la fotografía, más que un hobby o una forma
                de ganarse la vida, es más bien arte: una forma de expresarse,
                de capturar lo que sientes y lo que ves, de ir más allá de la
                experiencia sensorial.
              </p>
            </div>
          </div>
        </Col> */}
    </Container>
  );
};

export default gridContent;
