import React from 'react';
import { Row } from 'react-bootstrap';
import SpecsContent from '../../components/specsContent/SpecsContent';
import './SectionSpecs.scss';

export const SectionSpecs = () => {
    return (
        <Row className="RootSpecs">
            <div className="RootSpecs-inside" style={{ zIndex: "99" }}>
                <SpecsContent />
            </div>
        </Row >
    );
};

export default SectionSpecs;