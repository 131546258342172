import React from 'react';
import { Row } from 'react-bootstrap';
import { JumbotronArtist } from '../../components/jumbotronArtist/JumbotronArtist';
import './SectionArtist.scss';

export const SectionArtist = (props) => {
    return (
        <Row className="RootSectionArtist" id="accessPage">
            <div className="RootSectionArtist-inside" style={{ zIndex: "99" }}>
                <JumbotronArtist />
            </div>
        </Row >
    );
};
export default SectionArtist;
