import React from 'react';
import { Row } from 'react-bootstrap';
import './ImgSection.scss';

export const Home = (props) => {
    return (
        <Row className="RootImgSection" id="accessPage">
            <div className="imgSection" data-aos="fade-up" data-aos-duration="1000"></div>
        </Row >
    );
};
export default Home;
