import React, { useContext, useRef } from 'react';
import { Carousel, CardDeck, Card, Button } from 'react-bootstrap';

//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//ICON
import { faPlay, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { ResponsiveEmbed } from 'react-bootstrap';
import Slider from 'react-slick';
import CustomNextArrow from '../../../../components/customSlickArrows/CustomArrowsSlickRight';
import CustomPrevArrow from '../../../../components/customSlickArrows/CustomArrowsSlickLeft';
import { PlayerTogglerContext } from '../../../../layout/Layout';

import testImg1 from '../../../../assets/imgs/test/1.jpg';
import testImg2 from '../../../../assets/imgs/test/2.jpg';
import testImg3 from '../../../../assets/imgs/test/3.jpg';
import testImg4 from '../../../../assets/imgs/test/4.jpg';
import testImg5 from '../../../../assets/imgs/test/5.jpg';
import testImg6 from '../../../../assets/imgs/test/6.jpg';
import testImg7 from '../../../../assets/imgs/test/7.jpg';
import testImg8 from '../../../../assets/imgs/test/8.jpg';
import testImg9 from '../../../../assets/imgs/test/9.jpg';
import testImg1Webp from '../../../../assets/imgs/test/1.webp';
import testImg2Webp from '../../../../assets/imgs/test/2.webp';
import testImg3Webp from '../../../../assets/imgs/test/3.webp';
import testImg4Webp from '../../../../assets/imgs/test/4.webp';
import testImg5Webp from '../../../../assets/imgs/test/5.webp';
import testImg6Webp from '../../../../assets/imgs/test/6.webp';
import testImg7Webp from '../../../../assets/imgs/test/7.webp';
import testImg8Webp from '../../../../assets/imgs/test/8.webp';
import testImg9Webp from '../../../../assets/imgs/test/9.webp';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';

export const TestimonialsContent = () => {
  const slider = useRef();
  const PlayerToggler = useContext(PlayerTogglerContext);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slideCount: 1,
    slidesToScroll: 1,
    pauseOnFocus: true,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: (
      <CustomNextArrow
        className={'customArrows'}
        sliderArrowMouse="sliderArrowMouse"
        to="next"
      />
    ),
    prevArrow: (
      <CustomPrevArrow
        className="customArrows"
        sliderArrowMouse="sliderArrowMouse"
        to="prev"
      />
    ),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          // arrows: true,
          autoplaySpeed: 10000,
        },
        nextArrow: (
          <CustomNextArrow
            className={'customArrows'}
            sliderArrowMouse="sliderMouse"
            to="next"
          />
        ),
        prevArrow: (
          <CustomPrevArrow
            className="customArrows"
            sliderArrowMouse="sliderMouse"
            to="prev"
          />
        ),
      },
    ],
  };

  return (
    <div className="sectionContainerTestimonials">
      <div className="fluid">
        <div className="titleSection">
          <h1 className="title">¿QUÉ OPINAN MIS ALUMNOS?</h1>
        </div>
        <div className="testWrapSection">
          <div className="d-none d-lg-block">
            <CardDeck className="cardDeckTest">
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/569460023?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg1Webp} />
                    <img alt="" src={testImg1} />
                  </picture>
                  {/* <img alt="" src={testImg1} /> */}
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Jhon</h4>
                  <p className="testCite">
                    “Cuando me compré mi primera cámara me frustré por no saber
                    utilizar los parámetros. Me compré tu curso y solo con los
                    primeros módulos he visto un avance espectacular en mis
                    fotografías”.
                  </p>
                </Card.Body>
              </Card>
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/570241472?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg2Webp} />
                    <img alt="" src={testImg2} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Daniel</h4>
                  <p className="testCite">
                    Empezó desde 0 y recomienda el curso a todo aquel que quiera
                    convertirse en buen fotógrafo.
                  </p>
                </Card.Body>
              </Card>
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/570252889?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg3Webp} />
                    <img alt="" src={testImg3} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Luis</h4>
                  <p className="testCite">
                    “He mejorado muchísimo en la calidad de mis fotos. Gracias
                    Marcos por las ganas que nos das de seguir aprendiendo y la
                    forma en que transmites las cosas.”
                  </p>
                </Card.Body>
              </Card>
            </CardDeck>

            <div className="btnWrap">
              <Button
                onClick={() => {
                  ScrollToSmooth(document.querySelector('#payment'));
                }}
                bsPrefix
                className="buttonTestimonial enrollArtist"
              >
                <p className="d-none d-lg-block">
                  ¡Yo también quiero ser un caso de éxito!
                </p>
                <p className="d-block d-lg-none">
                  ¡Yo también quiero
                  <br />
                  ser un caso de éxito!
                </p>
              </Button>
            </div>
            <CardDeck className="cardDeckTest">
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/569460311?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg4Webp} />
                    <img alt="" src={testImg4} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Alfredo</h4>
                  <p className="testCite">
                    “La mejor inversión que he hecho hasta el momento. He
                    aprendido mucho más con el curso que en la universidad.”
                  </p>
                </Card.Body>
              </Card>
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/569460106?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg5Webp} />
                    <img alt="" src={testImg5} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Álvaro</h4>
                  <p className="testCite">
                    “Ha hecho que la fotografía sea más que un hobby para mi.”
                  </p>
                </Card.Body>
              </Card>
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/569460279?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg6Webp} />
                    <img alt="" src={testImg6} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Susana</h4>
                  <p className="testCite">
                    “He aprendido a utilizar el modo manual de la cámara y a
                    sacar las fotografías que yo quiero. Gracias Marcos por
                    enseñarnos a hacer fotografías en condiciones”.
                  </p>
                </Card.Body>
              </Card>
            </CardDeck>
            <div className="btnWrap">
              <Button
                onClick={() => {
                  ScrollToSmooth(document.querySelector('#payment'));
                }}
                bsPrefix
                className="buttonTestimonial enrollArtist"
              >
                <p className="d-none d-lg-block">
                  ¡Yo también quiero ser un caso de éxito!
                </p>
                <p className="d-block d-lg-none">
                  ¡Yo también quiero
                  <br />
                  ser un caso de éxito!
                </p>
              </Button>
            </div>
            <CardDeck className="cardDeckTest">
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/569530395?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg7Webp} />
                    <img alt="" src={testImg7} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Josue</h4>
                  <p className="testCite">
                    “He mejorado en composición, colores y creatividad. También
                    he encontrado mi estilo en fotografía. Me parece una
                    pasada.”
                  </p>
                </Card.Body>
              </Card>
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/569460181?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg8Webp} />
                    <img alt="" src={testImg8} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Miguel</h4>
                  <p className="testCite">
                    “Empecé sabiendo muy poco sobre fotografía y en poco tiempo
                    estoy consiguiendo la calidad profesional que buscaba”.
                  </p>
                </Card.Body>
              </Card>
              <Card className="testimonialsCard">
                <Card.Header className="testimonialsCardHeader">
                  <div
                    className="playContainer"
                    onClick={() => {
                      PlayerToggler.setToggler({
                        opened: !PlayerToggler.toggler,
                        src: 'https://player.vimeo.com/video/569499715?autoplay=1',
                      });
                    }}
                  >
                    <div className="playWrap">
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </div>
                  <picture className="JumboImage">
                    <source type="image/webp" srcSet={testImg9Webp} />
                    <img alt="" src={testImg9} />
                  </picture>
                </Card.Header>
                <Card.Body className="testimonialsCardBody">
                  <h4 className="testCiteName">Blanca</h4>
                  <p className="testCite">
                    “Vi la oferta y no me lo pensé dos veces. Es el mejor curso
                    de fotografía. Inscribete porque no te vas a arrepentir”.
                  </p>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
          <div className="d-block d-lg-none">
            <Slider {...settings} ref={slider}>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/569460023?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg1Webp} />
                      <img alt="" src={testImg1} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Jhon</h4>
                    <p className="testCite">
                      “Cuando me compré mi primera cámara me frustré por no
                      saber utilizar los parámetros. Me compré tu curso y solo
                      con los primeros módulos he visto un avance espectacular
                      en mis fotografías”.
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/570241472?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg2Webp} />
                      <img alt="" src={testImg2} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Daniel</h4>
                    <p className="testCite">
                      Empezó desde 0 y recomienda el curso a todo aquel que
                      quiera convertirse en buen fotógrafo.
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/570252889?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg3Webp} />
                      <img alt="" src={testImg3} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Luis</h4>
                    <p className="testCite">
                      “He mejorado muchísimo en la calidad de mis fotos. Gracias
                      Marcos por las ganas que nos das de seguir aprendiendo y
                      la forma en que transmites las cosas.”
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/569460311?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg4Webp} />
                      <img alt="" src={testImg4} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Alfredo</h4>
                    <p className="testCite">
                      “La mejor inversión que he hecho hasta el momento. He
                      aprendido mucho más con el curso que en la universidad.”
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/569460106?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg5Webp} />
                      <img alt="" src={testImg5} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Álvaro</h4>
                    <p className="testCite">
                      “Ha hecho que la fotografía sea más que un hobby para mi.”
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </Slider>
            <div className="btnWrap">
              <Button
                onClick={() => {
                  ScrollToSmooth(document.querySelector('#payment'));
                }}
                bsPrefix
                className="buttonTestimonial enrollArtist"
              >
                <p className="d-none d-lg-block">
                  ¡Yo también quiero ser un caso de éxito!
                </p>
                <p className="d-block d-lg-none">
                  ¡Yo también quiero
                  <br />
                  ser un caso de éxito!
                </p>
              </Button>
            </div>
            <Slider {...settings} ref={slider}>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/569460279?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg6Webp} />
                      <img alt="" src={testImg6} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Susana</h4>
                    <p className="testCite">
                      “He aprendido a utilizar el modo manual de la cámara y a
                      sacar las fotografías que yo quiero. Gracias Marcos por
                      enseñarnos a hacer fotografías en condiciones”.
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/569530395?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg7Webp} />
                      <img alt="" src={testImg7} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Josue</h4>
                    <p className="testCite">
                      “He mejorado en composición, colores y creatividad.
                      También he encontrado mi estilo en fotografía. Me parece
                      una pasada.”
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/569460181?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg8Webp} />
                      <img alt="" src={testImg8} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Miguel</h4>
                    <p className="testCite">
                      “Empecé sabiendo muy poco sobre fotografía y en poco
                      tiempo estoy consiguiendo la calidad profesional que
                      buscaba”.
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="slickSliderDiv">
                <Card className="testimonialsCard">
                  <Card.Header className="testimonialsCardHeader">
                    <div
                      className="playContainer"
                      onClick={() => {
                        PlayerToggler.setToggler({
                          opened: !PlayerToggler.toggler,
                          src: 'https://player.vimeo.com/video/569499715?autoplay=1',
                        });
                      }}
                    >
                      <div className="playWrap">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </div>
                    <picture className="JumboImage">
                      <source type="image/webp" srcSet={testImg9Webp} />
                      <img alt="" src={testImg9} />
                    </picture>
                  </Card.Header>
                  <Card.Body className="testimonialsCardBody">
                    <h4 className="testCiteName">Blanca</h4>
                    <p className="testCite">
                      “Vi la oferta y no me lo pensé dos veces. Es el mejor
                      curso de fotografía. Inscribete porque no te vas a
                      arrepentir”.
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </Slider>
          </div>
          <div className="btnWrap">
            <Button
              onClick={() => {
                ScrollToSmooth(document.querySelector('#payment'));
              }}
              bsPrefix
              className="buttonTestimonial enrollArtist"
            >
              <p className="d-none d-lg-block">
                ¡Yo también quiero ser un caso de éxito!
              </p>
              <p className="d-block d-lg-none">
                ¡Yo también quiero
                <br />
                ser un caso de éxito!
              </p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsContent;
