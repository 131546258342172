import React, { useEffect, useState } from "react";
import validatePromCode from "../../assets/js/discount";
import "./Banner.scss";

function Banner({ content }) {
   const [display, setDisplay] = useState(false);
   useEffect(() => {
      const { discount_name } = validatePromCode();
      if (discount_name === "2021" || discount_name === "2021-general") {
         console.log(discount_name);
         setDisplay(true);
      }
   }, []);
   return <>{display && <h5 className="banner">Bienvenido 2021</h5>}</>;
}

export default Banner;
