import React from "react";
import { Accordion, Card } from "react-bootstrap";
import "./FaqContent.scss";

//FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//ICON
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const FaqContent = (props) => {
  return (
    <div className="sectionContainerexport default faqContent">
      <div className="fluid" id="sectionFaq">
        <div
          className="titleSection export default faqTitle"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="title">FAQ</h1>
        </div>
        <Accordion className="cardexport default cardFaqContent">
          <Card data-aos="fade-up" data-aos-duration="1000">
            <Accordion.Toggle as={Card.Header} eventKey="0">
              ¿Qué es este curso?
              <span className="faqContentIcon">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ul className="accodionContent">
                  <li>
                    Principios es un curso online sobre fotografía impartido por
                    Marcos Alberca, donde aprenderás todas los secretos de la
                    fotografía para pasar de principiante a avanzado.
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card data-aos="fade-up" data-aos-duration="1000">
            <Accordion.Toggle as={Card.Header} eventKey="1">
              ¿Por quién está hecho?
              <span className="faqContentIcon">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <ul className="accodionContent">
                  <li>El curso es impartido por Marcos Alberca en persona.</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card data-aos="fade-up" data-aos-duration="1000">
            <Accordion.Toggle as={Card.Header} eventKey="2">
              ¿Cuanto dura esta formación?
              <span className="faqContentIcon">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <ul className="accodionContent">
                  <li>
                    El tiempo que necesites para realizarla, no hay limite de
                    tiempo y aún cuando la hayas completado, podrás entrar las
                    veces que quieras a revisar la información.
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card data-aos="fade-up" data-aos-duration="1000">
            <Accordion.Toggle as={Card.Header} eventKey="3">
              ¿Cómo funciona el pago?
              <span className="faqContentIcon">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <ul className="accodionContent">
                  <li>
                    Nuestra plataforma acepta tarjetas de crédito, débito y
                    también puedes pagar con PayPal.
                  </li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

export default FaqContent;
