import React, {
  useEffect,
  lazy,
  Suspense,
  useState,
  useRef,
  createContext,
} from 'react';
import { Container } from 'react-bootstrap';
// import { CustomNavbar as Navbar } from "../components/navbar/Navbar";

// STYLE
import './Layout.css';

// Libraries
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';
import FsLightbox from 'fslightbox-react';

import DeadLinePromo from '../components/countDownPromo/DeadLinePromo';

const Navbar = lazy(() => import('../components/navbar/Navbar'));

export const PromoDates = {
  dateActive: new Date('2021-11-26T12:00:00+01:00'),
  dateExpire: new Date('2021-11-29T23:59:00+01:00'),
  todayDate: new Date(),
};

export const isPromoActive = () => {
  const dateActive = PromoDates.dateActive;
  const dateExpire = PromoDates.dateExpire;
  const todayDate = new Date();
  if((new URLSearchParams(window.location.search).get('chkprm')) == 1) return true;
  return dateActive < todayDate && dateExpire > todayDate;
};

export const isPromoActiveTimer = () => {
  return {
    isActive: !localStorage.getItem('promoTimerCompleted'),
    timer: 172800000, // 172800000
  };
};

export const isBlackFriday = false;
export const isExamenes = false;

export const PlayerTogglerContext = createContext(null);

export const Layout = (props) => {
  useEffect(() => {
    AOS.init({ disable: window.innerWidth < 991 });
    new SmoothScroll('a[href*="#"]', { offset: 50, speed: 100 });
  }, []);

  const [toggler, setToggler] = useState({
    opened: false,
    src: null,
  });
  let PlayerToggler = useRef(setToggler);
  const getPlayerToggler = () => {
    return PlayerToggler.current;
  };

  return (
    <PlayerTogglerContext.Provider
      value={{ getPlayerToggler, toggler, setToggler }}
    >
      {isPromoActive() ? ( <DeadLinePromo /> ) : ( '' )}
      <Container nogutters="true" fluid id="inicio">
        {/* <Suspense fallback={<div className="suspenseImg"></div>}>
        <Navbar />
      </Suspense> */}
        {props.children}

        <FsLightbox
          toggler={toggler}
          sources={[
            <iframe
              src={toggler.src}
              width="1920"
              height="1080"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="VSL - NFT MASTERY"
            ></iframe>,
          ]}
          type="video"
        />
      </Container>
    </PlayerTogglerContext.Provider>
  );
};

export default Layout;
