import React from 'react';

export const CustomNextArrow = (props) => {
  const { className, onClick, sliderArrowMouse } = props;
  return (
    <div className={`${className} ${sliderArrowMouse}`} onClick={onClick}>
      <div className="slickNext link" onClick={onClick}>
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 8 8"
        >
          <path
            fill="#FFFFFF"
            className="st0"
            d="M2.8,0L1.3,1.5L3.8,4L1.3,6.5L2.8,8l4-4L2.8,0z"
          />
        </svg>
      </div>
    </div>
  );
};

export default CustomNextArrow;
