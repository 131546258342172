import React from 'react';
import { Row } from 'react-bootstrap';
import CharactContent from '../../components/charactContent/CharactContent';
import './SectionCharact.scss';

export const SectionCharact = () => {
    return (
        <Row className="RootCharact">
            <div className="RootCharact-inside" style={{ zIndex: "99" }}>
                <CharactContent />
            </div>
        </Row >
    );
};

export default SectionCharact;