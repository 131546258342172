import React from 'react';
import { Jumbotron, Button, Row, Col, ResponsiveEmbed } from 'react-bootstrap';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';
import { isPromoActive } from '../../../../layout/Layout';
import './JumbotronHome.scss';

export const JumbotronContent = () => {
  return (
    <Row>
      <Col xs={12} lg={5} className="homeCol">
        <Jumbotron className="jumbotronHome">
          <h1 className="principiosTitle">Domina la fotografía conmigo</h1>

          <p className="principiosP">
            Sácale partido a tu creatividad e impacta con{' '}
            <br className="d-none d-lg-block" />
            tus fotografías. De 0 a 100 en 4 semanas
          </p>

          <p className="buttonsGroup">
            <Button
              onClick={() => {
                ScrollToSmooth(document.querySelector('#payment'));
              }}
              bsPrefix
              className="buttonsHome enroll"
            >
              {isPromoActive() ? (
                <span>aprovechar esta oferta de 69€</span>
              ) : (
                <span>Inscríbete!</span>
              )}
            </Button>
          </p>
        </Jumbotron>
      </Col>
      <Col xs={12} lg={7} className="homeCol">
        <div className="embedVideo">
          <ResponsiveEmbed aspectRatio="16by9" data-aos="fade-up">
            <iframe
              width="640"
              height="360"
              src="https://player.vimeo.com/video/569528113?h=d7aa78b3a8%22"
              title="homePageVideo"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </ResponsiveEmbed>
        </div>
      </Col>
    </Row >
  );
};

export default JumbotronContent;
