import React from "react";
// import { } from 'react-bootstrap';
import "./SectionFaq.scss";
import FaqContent from "../../components/faqContent/FaqContent";

export const SectionFaq = (props) => {
  return (
    <div className="RootFaq">
      <div className="fluid RootFaq-inside">
        <div className="">
          <FaqContent />
        </div>
      </div>
    </div>
  );
};

export default SectionFaq;
