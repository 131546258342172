import React from 'react';
import { Row } from 'react-bootstrap';
import { JumbotronContent as Jumbotron } from '../../components/jumbotronBrand/JumbotronBrand';
import './BrandPage.scss';

export const BrandPage = (props) => {
    return (
        <Row className="RootBrand" id="brandPage">
            <div className="RootBrand-inside" data-aos="fade-up" style={{ zIndex: "99" }}>
                <Jumbotron />
            </div>
        </Row >
    );
};
export default BrandPage;
