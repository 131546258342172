import React from 'react';
import { Row } from 'react-bootstrap';
import { isPromoActive } from '../../../../layout/Layout';
import PaymentCard from '../../components/paymentCard/PaymentCard';
import './PaymentSection.scss';

export const PaymentSectionTop = () => {
  return (
    <Row className="RootPayment RootPaymentTop">
      <div
        className="RootPayment-inside"
        style={{ zIndex: '99' }}
        id="paymentTop"
      >
        <PaymentCard />
      </div>
    </Row>
  );
};

export default PaymentSectionTop;
