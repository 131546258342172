import React, { useState, useEffect } from 'react';
import { CardDeck, Card, Button } from 'react-bootstrap';
import './PaymentCard.scss';

import validatePromCode from '../../../../assets/js/discount';
import { isPromoActive } from '../../../../layout/Layout';

export const PaymentCard = () => {
  const [discount, setDiscount] = useState({});

  useEffect(() => {
    const discountData = validatePromCode();
    if (discountData) {
      setDiscount(discountData);
    }
  }, []);
  return (
    <div className="paymentContainer cardPayment">
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center paymentCol d-block">
            {isPromoActive() ? (
              <div className="paymentTitleWrap">
                <h1 className="titlePaymentSection">
                  Oferta por fin de Exámenes
                </h1>
                <p className="cardContent">Selecciona tu método de pago</p>
              </div>
            ) : (
              <div className="paymentTitleWrap">
                <h1 className="titlePaymentSection">Oferta exclusiva</h1>
              </div>
            )}
            <CardDeck>
              <Card
                className="cardStyle d-md-flex cardPayment"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <Card.Body className="d-md-flex bodyCardPayment">
                  <div className="paymentTexts">
                    {/* <Card.Text className="iconProblem cardIcon"></Card.Text> */}

                    <Card.Title>
                      <span
                        className={
                          discount.price_eur === 79
                            ? 'd-none'
                            : 'striked-price'
                        }
                      >
                        79€
                      </span>
                    </Card.Title>
                    <Card.Title className="paymentTitle">
                      {discount.price_eur}€
                    </Card.Title>
                    <div className="hrLine"></div>
                    {/* <Card.Text className="cardContent">
                      Pago con euros
                    </Card.Text> */}
                    <div className="buttonsGroupPayment">
                      <Button
                        target="_blank"
                        href={discount.eur_url}
                        bsPrefix
                        className="buttonPayment enrollPayment"
                      >
                        <p>Pagar con Tarjeta</p>
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="cardStyle d-md-flex cardPayment"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <Card.Body className="d-md-flex bodyCardPayment">
                  <div className="paymentTexts">
                    {/* <Card.Text className="iconProblem cardIcon"></Card.Text> */}

                    <Card.Title>
                      <span
                        className={
                          discount.price_eur === 79
                            ? 'd-none'
                            : 'striked-price'
                        }
                      >
                        $87
                      </span>
                    </Card.Title>
                    <Card.Title className="paymentTitle">
                      {discount.price_usd}$
                    </Card.Title>
                    <div className="hrLine"></div>
                    {/* <Card.Text className="cardContent">
                      Pago con dólares
                    </Card.Text> */}
                    <div className="buttonsGroupPayment">
                      <Button
                        target="_blank"
                        href={discount.usd_url}
                        bsPrefix
                        className="buttonPayment enrollPayment"
                      >
                        <p>Pagar con PayPal</p>
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
