import React, { useEffect } from 'react';
import Countdown from 'react-countdown';
import { isPromoActiveTimer, PromoDates, isBlackFriday } from '../../../layout/Layout';

const CountDownPromoTimer = () => {
  useEffect(() => {
    if (!localStorage.getItem('promoDateNow')) {
      localStorage.setItem('promoDateNow', Date.now());
    }
  }, []);
  const renderer = ({ formatted: { hours, minutes, seconds }, completed }) => {
    if (completed) {
      // Render a completed state
      window.location.pathname = '/';
      localStorage.setItem('promoTimerCompleted', true);
      return (
        <h2 className="textStroke">
          {hours + 'h '}
          {minutes + 'm '}:{seconds + 's'}
        </h2>
      );
    } else {
      // Render a countdown
      let days = 0;
      if (hours > 24) {
        days = parseInt(hours / 24, 10);
        hours = (hours - (days * 24)) % 24;
      }
      let hoursToMilis = hours * 60 * 60 * 1000;
      let minsToMilis = minutes * 60 * 1000;
      let secsToMilis = seconds * 1000;
      let totalCurrTime = hoursToMilis + minsToMilis + secsToMilis;
      // if (totalCurrTime != null && totalCurrTime !== 'NaN') {
      //   localStorage.setItem('promoCurrTime', totalCurrTime);
      // }
      return (
        <h2 className="textStroke">
          {(days > 0) ? days + 'd : ' : ''}{hours + 'h '}: {minutes + 'm '}: {seconds + 's'}
        </h2>
      );
    }
  };

  const dateExpire = PromoDates.dateExpire;
  return (
    <Countdown
      // autoStart={false}
      date={dateExpire.getTime()}
      daysInHours
      // onComplete={(e) => window.location.reload()}
      renderer={renderer}
    />
  );
  /*const dateExpire = isPromoActiveTimer().timer;
  return (
    <Countdown
      // autoStart={false}
      date={
        localStorage.getItem('promoDateNow')
          ? parseInt(localStorage.getItem('promoDateNow')) + dateExpire
          : Date.now() + dateExpire
      }
      daysInHours
      // onComplete={(e) => window.location.reload()}
      renderer={renderer}
    />
);*/
};

export default CountDownPromoTimer;
