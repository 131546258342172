import React from 'react';
import { Row } from 'react-bootstrap';
import { JumbotronJoinUs } from '../../components/jumbotronJoinUs/JumbotronJoinUs';
import './JoinUs.scss';

export const JoinUs = (props) => {
    return (
        <Row className="RootJoinUs" id="accessPage">
            <div className="RootJoinUs-inside" data-aos="fade-up" data-aos-duration="1000" style={{ zIndex: "99" }}>
                <JumbotronJoinUs />
            </div>
        </Row >
    );
};
export default JoinUs;
