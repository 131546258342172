import React from 'react';
import { Row } from 'react-bootstrap';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';
import { JumbotronContent as Jumbotron } from '../../components/jumbotronHome/JumbotronHome';
import './Home.scss';

export const Home = (props) => {
  return (
    <Row className="RootHome">
      <div className="RootHome-inside" style={{ zIndex: '99' }}>
        <Jumbotron />
        <div className="homeArrowDown">
          <svg
            width="66"
            height="30"
            viewBox="0 0 66 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              ScrollToSmooth(document.querySelector('#paymentTop'));
            }}
          >
            <path
              d="M2 2L33.3559 27L63.8644 2"
              stroke="white"
              stroke-width="4"
            />
          </svg>
        </div>
      </div>
    </Row>
  );
};
export default Home;
