import React, { useRef } from 'react';
import { Col, Row, ResponsiveEmbed, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import ScrollToSmooth from '../../components/scrollToSmooth/ScrollToSmooth';

const ThankVid = () => {
  const videoRef = useRef(null);

  return (
    <Row className="RootThankVid">
      <Row className="RootThankVid-inside">
        {/* <Col xs={12} className="titleSection titleThank">
          <h1 className="title">¡GRACIAS POR TU COMPRA!</h1>
        </Col> */}
        <Col xs={12} lg={6} className="titleSection">
          <div>
            <h1 className="title">¡GRACIAS POR TU COMPRA!</h1>
            <div className="d-none d-lg-block">
              <p className="principiosP">
                Desde este momento vamos a trabajar para{' '}
                <span className="featuredText">lograr tus objetivos</span>.
              </p>
              <p className="principiosP">
                En breve te llegará un{' '}
                <span className="featuredText">
                  email con toda la información
                </span>{' '}
                para entrar en la plataforma y arrancar con el programa. Sin en
                5 minutos no has recibido el email,{' '}
                <span className="featuredText">revisa tu bandeja</span> de spam,
                probablemente esté allí.
              </p>
              <Button
                bsPrefix
                className="buttonsHome enroll"
                href="https://sso.teachable.com/secure/329405/users/sign_in"
              >
                <span>Ir al curso</span>
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6} className="vidSection">
          <ResponsiveEmbed aspectRatio="16by9" className="embedContainerThank">
            <ReactPlayer
              ref={videoRef}
              id="interviewIframe"
              url="https://player.vimeo.com/video/567464605"
              width="100%"
              height="100%"
              controls={true}
              //   playing={playingState}
              //   onPlay={onPlay}
              //   onPause={onPause}
            />
          </ResponsiveEmbed>
        </Col>
        <Col xs={12} lg={6} className="titleSection d-block d-lg-none">
          <div>
            {/* <h1 className="title">¡GRACIAS POR TU COMPRA!</h1> */}
            <p className="principiosP">
              Desde este momento vamos a trabajar para{' '}
              <span className="featuredText">lograr tus objetivos</span>.
            </p>
            <p className="principiosP">
              En breve te llegará un{' '}
              <span className="featuredText">
                email con toda la información
              </span>{' '}
              para entrar en la plataforma y arrancar con el programa. Sin en 5
              minutos no has recibido el email,{' '}
              <span className="featuredText">revisa tu bandeja</span> de spam,
              probablemente esté allí.
            </p>

            <Button
              bsPrefix
              className="buttonsHome enroll"
              href="https://sso.teachable.com/secure/329405/users/sign_in"
            >
              <span>Ir al curso</span>
            </Button>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default ThankVid;
