import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import './SectionCard.scss';
import img1 from '../../../../assets/imgs/sectionCards/1.jpg';
import img2 from '../../../../assets/imgs/sectionCards/2.jpg';
export const JumbotronContent = () => {
  return (
    <div className="sectionContainer cardSection">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 text-center solutionsCol">
            <CardDeck>
              <Card
                className="cardStyle d-md-flex cardSolutions"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <Card.Header>
                  <Card.Img src={img1} className="imgCardSolutions" />
                </Card.Header>
                <Card.Body className="d-md-flex bodyCardSolutions">
                  <div className="solutionsTexts">
                    <Card.Text className="iconProblem cardIcon"></Card.Text>
                    <Card.Title className="cardTitle">PROBLEMA</Card.Title>
                    <div className="hrLine"></div>
                    <Card.Text className="cardContent">
                      Aprender a hacer fotos de calidad no es una tarea fácil.
                      Requiere práctica, perseverancia, decisión, y muchas horas
                      de prueba y error. Es fácil desmotivarse tras echarle
                      decenas de horas de práctica y apenas ver mejora.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
              <Card
                className="cardStyle d-md-flex cardSolutions"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <Card.Header>
                  <Card.Img src={img2} className="imgCardSolutions" />
                </Card.Header>

                <Card.Body className="d-md-flex bodyCardSolutions">
                  <div className="solutionsTexts">
                    <Card.Text className="iconSolution cardIcon"></Card.Text>
                    <Card.Title className="cardTitle">SOLUCIÓN</Card.Title>
                    <div className="hrLine"></div>
                    <Card.Text className="cardContent">
                      Por eso decidí crear este curso llamado "PRINCIPIOS". Para
                      que aprendas a dominar la cámara y conseguir el mejor
                      resultado en tus fotografías en 4 semanas como máximo, y
                      que no te lleve años como ha sido mi caso.
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JumbotronContent;
