import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import './CharactContent.scss';
//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//ICON
import { faVideo, faPlus, faAdjust } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

export const CharactContent = () => {
  return (
    <div className="sectionContainerCharact">
      <div className="fluid h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center" id="sectionCharact">
            <div
              className="titleSection"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="title">Lo que te llevarás del curso</h1>
              <h1 className="title textColorSecondary">
                Y que no te llevarás en otra parte
              </h1>
            </div>
            <CardDeck className="cardDeckSpecs">
              <Card
                className="cardSpecs d-md-block ml-md-4"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <Card.Body>
                  <Card.Text className="iconSpecs cardIcon">
                    <FontAwesomeIcon
                      className="specsIcon videoIcon"
                      icon={faVideo}
                    />
                  </Card.Text>
                  <Card.Title className="cardTitle">La Cámara</Card.Title>
                  <Card.Text className="cardContent">
                    Saber usar la cámara es lo que nos convierte en artistas y
                    nos separa del resto. Si ponemos todo en automático, la
                    cámara tomaría las decisiones… y no queremos eso.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="cardSpecs d-md-block"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <Card.Body>
                  <Card.Text className="iconSpecs cardIcon">
                    <FontAwesomeIcon className="specsIcon" icon={faAdjust} />
                  </Card.Text>
                  <Card.Title className="cardTitle">Iluminación</Card.Title>
                  <Card.Text className="cardContent">
                    Conocer todas las posibles configuraciones de iluminación y
                    aprender a usarlas de forma creativa nos permitirá hacer
                    fotos especiales y auténticas.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="cardSpecs d-md-block"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <Card.Body>
                  <Card.Text className="iconSpecs cardIcon">
                    <FontAwesomeIcon
                      className="specsIcon userIcon"
                      icon={faUserCircle}
                    />
                  </Card.Text>
                  <Card.Title className="cardTitle">Retratos</Card.Title>
                  <Card.Text className="cardContent">
                    Descubre cómo capturar la esencia y los matices de una
                    persona, tanto física como moral, y cómo transmitir una
                    emoción poderosa a través de una imagen.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                className="cardSpecs d-md-block mr-md-4"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <Card.Body>
                  <Card.Text className="iconSpecs cardIcon">
                    <FontAwesomeIcon className="specsIcon" icon={faPlus} />
                  </Card.Text>
                  <Card.Title className="cardTitle">Y Mucho Más</Card.Title>
                  <Card.Text className="cardContent">
                    Narra historias, transmite una idea, comunica una emoción, …
                    Con una sola foto. En este curso aprenderás que la
                    fotografía es más que simplemente fotografía.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharactContent;
