import React from 'react';
import TestimonialsContent from '../../components/testimonialsContent/TestimonialsContent';

export const SectionTestimonials = () => {
  return (
    <div className="RootTestimonials" id="testimonios">
      <div className="fluid RootTestimonials-inside">
        <div className="">
          <TestimonialsContent />
        </div>
      </div>
    </div>
  );
};

export default SectionTestimonials;
