import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './AccordionContent.scss';

//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//ICON
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const AccordionContent = () => {
  return (
    <div className="sectionContainerAccordionContent">
      <div className="fluid h-100">
        <div
          className="titleSection accordionContentMarges"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="title accordionTitleSection">
            ¿QUE CONTIENE ESTE CURSO?
          </h1>
        </div>
        <Accordion className="cardAccordionContent">
          <Card data-aos="fade-up" data-aos-duration="1000">
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Presentación
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Presentación</li>
                  <li className="indentation">Observación y percepción</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card data-aos="fade-up" data-aos-duration="1000">
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Cómo funciona la cámara I y II
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Partes de la cámara</li>
                </ul>
                <ul className="accodionContent">
                  <li>Triángulo de exposición</li>
                </ul>
                <ul className="accodionContent">
                  <li>Modos de uso</li>
                </ul>
                <ul className="accodionContent">
                  <li>Modos de enfoque</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card data-aos="fade-up" data-aos-duration="1000">
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Técnicas, composición y errores
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Técnicas</li>
                  <li className="indentation">Barrido</li>
                  <li className="indentation">Congelado</li>
                  <li className="indentation">Larga exposición</li>
                </ul>
                <ul className="accodionContent">
                  <li>Composición</li>
                  <li className="indentation">Teoría de composición</li>
                  <li className="indentation">El encuadre</li>
                  <li className="indentation">Errores comunes</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="1000"
            // className="d-none d-lg-flex"
          >
            <Accordion.Toggle as={Card.Header} eventKey="3">
              Illuminación
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Fuentes de luz</li>
                  <li className="indentation">Según la procedencia</li>
                  <li className="indentation">Según la difusión</li>
                  <li className="indentation">Según la dirección</li>
                </ul>
                <ul className="accodionContent">
                  <li>Ejemplos prácticos</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="1000"
            // className="d-none d-lg-flex"
          >
            <Accordion.Toggle as={Card.Header} eventKey="4">
              Narración visual ¿Cuando una foto es buena?
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <ul className="accodionContent">
                  <li>El mensaje</li>
                </ul>
                <ul className="accodionContent">
                  <li>Recursos narrativos</li>
                </ul>
                <ul className="accodionContent">
                  <li>Emociones y sentimientos</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="1000"
            // className="d-none d-lg-flex"
          >
            <Accordion.Toggle as={Card.Header} eventKey="5">
              Fotografía de retrato
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Planos</li>
                </ul>
                <ul className="accodionContent">
                  <li>Aplicar la teoría</li>
                </ul>
                <ul className="accodionContent">
                  <li>Dirección de pose</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="1000"
            // className="d-none d-lg-flex"
          >
            <Accordion.Toggle as={Card.Header} eventKey="6">
              Fotografía de paisaje y de producto
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Fotografía de paisaje</li>
                </ul>
                <ul className="accodionContent">
                  <li>Fotografía de producto</li>
                  <li className="indentation">Ejemplo práctico 1</li>
                  <li className="indentation">Ejemplo práctico 2</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="1000"
            // className="d-none d-lg-flex"
          >
            <Accordion.Toggle as={Card.Header} eventKey="7">
              Momentos del día. Diurno-nocturno.
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Noche</li>
                  <li className="indentation">Larga exposición</li>
                  <li className="indentation">Evitar errores</li>
                </ul>
                <ul className="accodionContent">
                  <li>Día soleado</li>
                  <li className="indentation">Luz dura</li>
                  <li className="indentation">Sombras</li>
                </ul>
                <ul className="accodionContent">
                  <li>Atardecer</li>
                  <li className="indentation">Exponer correctamente</li>
                  <li className="indentation">Siluetas</li>
                </ul>
                <ul className="accodionContent">
                  <li>Hora azul</li>
                  <li className="indentation">Luz suave</li>
                  <li className="indentation">Retrato en la hora azul</li>
                </ul>
                <ul className="accodionContent">
                  <li>Nublado</li>
                  <li className="indentation">Luz difusa</li>
                  <li className="indentation">Día lluvioso</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="1000"
            // className="d-none d-lg-flex"
          >
            <Accordion.Toggle as={Card.Header} eventKey="8">
              Varios escenarios. Objetivos.
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                <ul className="accodionContent">
                  <li>Diferentes escenarios para fotografía</li>
                  <li className="indentation">Interior con luz natural</li>
                  <li className="indentation">Interior con luz artificial</li>
                  <li className="indentation">Escenario nevado</li>
                  <li className="indentation">Escenario de playa</li>
                  <li className="indentation">Escenario de total oscuridad</li>
                  <li className="indentation">Escenario con agua</li>
                </ul>
                <ul className="accodionContent">
                  <li>Tipos de objetivos</li>
                  <li className="indentation">Distancia focal</li>
                  <li className="indentation">Luminosidad</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            data-aos="fade-up"
            data-aos-duration="1000"
            // className="d-none d-lg-flex"
          >
            <Accordion.Toggle as={Card.Header} eventKey="9">
              Planificación de una sesión. ¿Ahora qué?
              <span className="accordionIcon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="9">
              <Card.Body>
                <ul className="accodionContent">
                  <li>¿Cómo planificar una sesión?</li>
                  <li className="indentation">Iluminación</li>
                  <li className="indentation">Localización</li>
                  <li className="indentation">Equipo y material</li>
                </ul>
                <ul className="accodionContent">
                  <li>Fotografía como afición o profesión</li>
                </ul>
                <ul className="accodionContent">
                  <li>Salida al mundo laboral</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionContent;
