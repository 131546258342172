import React from 'react';
import { Row } from 'react-bootstrap';
import { JumbotronContent as Jumbotron } from '../../components/jumbotronAccess/JumbotronAccess';
import './AccessNow.scss';

export const Home = (props) => {
    return (
        <Row className="RootAccess" id="accessPage">
            <div className="RootAccess-inside" style={{ zIndex: "99" }}>
                <Jumbotron />
            </div>
        </Row >
    );
};
export default Home;
