import React from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';
import './JumbotronJoinUs.scss';

export const JumbotronJoinUs = () => {
  return (
    <Jumbotron className="jumbotronJoinUs">
      <div className="page-hero d-flex align-items-center justify-content-center joinUsContent">
        <div>
          <h1 className="joinUsTitle">
            ¿Quieres llevar tus fotos al siguiente nivel?
          </h1>
          <div className="btnWrap">
            <Button
              onClick={() => {
                ScrollToSmooth(document.querySelector('#payment'));
              }}
              bsPrefix
              className="buttonJoinUs enrollJoinUs"
            >
              <span>¡Únete a nosotros!</span>
            </Button>
          </div>
        </div>
      </div>
    </Jumbotron>
  );
};

export default JumbotronJoinUs;
