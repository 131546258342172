import React from 'react';
import { Row } from 'react-bootstrap';
import { JumbotronBottomAccess } from '../../components/jumbotronBottomAccess/JumbotronBottomAccess';
import './BottomAccess.scss';

export const BottomAccess = (props) => {
    return (
        <Row className="RootBottomAccess" id="accessPage">
            <div className="RootBottomAccess-inside" style={{ zIndex: "99" }}>
                <JumbotronBottomAccess />
            </div>
        </Row >
    );
};
export default BottomAccess;
