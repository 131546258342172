import React, { lazy, Suspense } from 'react';

import Layout from '../layout/Layout';
import ThankVid from './thankPage/ThankVid';

const Footer = lazy(() => import('../components/footer/Footer'));

export const ThankPage = (props) => {
  return (
    <Layout>
      <ThankVid />
      <Suspense fallback={<div className="suspenseImg"></div>}>
        <Footer />
      </Suspense>
    </Layout>
  );
};

export default ThankPage;
