import React from 'react';
import { Row, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import './Footer.scss';

import { Link } from 'react-router-dom';

//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//ICON
import {
  faYoutube,
  faTwitter,
  faTiktok,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

export const Footer = (props) => {
  return (
    <Row className="RootFooter">
      <div className="RootFooter-inside" style={{ zIndex: '99' }} id="footer">
        <div className="socialButtonsGroup">
          <ButtonToolbar
            aria-label="Toolbar with button groups"
            className="socialButtonsToolbar"
          >
            <ButtonGroup
              className="mr-2 socialButtons"
              aria-label="First group"
            >
              <Button
                target="_blank"
                href="https://www.youtube.com/channel/UC8hO9SteWO-kZokmAd7Bniw"
              >
                <FontAwesomeIcon className="socialIconStyle" icon={faYoutube} />
              </Button>
            </ButtonGroup>
            <ButtonGroup
              className="mr-2 socialButtons"
              aria-label="First group"
            >
              <Button
                target="_blank"
                href="https://www.instagram.com/marcosalberca/"
              >
                <FontAwesomeIcon
                  className="socialIconStyle"
                  icon={faInstagram}
                />
              </Button>
            </ButtonGroup>
            <ButtonGroup
              className="mr-2 socialButtons"
              aria-label="First group"
            >
              <Button
                target="_blank"
                href="https://www.tiktok.com/@marcosalberca?source=h5_m"
              >
                <FontAwesomeIcon className="socialIconStyle" icon={faTiktok} />
              </Button>
            </ButtonGroup>
            <ButtonGroup
              className="mr-2 socialButtons"
              aria-label="First group"
            >
              <Button
                target="_blank"
                href="https://twitter.com/MarcosAlbercaYT"
              >
                <FontAwesomeIcon className="socialIconStyle" icon={faTwitter} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="copyTextGroup">
        <div className="copyText">
          <Link
            to={(location) => ({
              ...location,
              pathname: '/politica-de-privacidad',
            })}
          >
            Política de Privacidad
          </Link>
          |
          <Link
            to={(location) => ({
              ...location,
              pathname: '/politica-de-cookies',
            })}
          >
            {' '}
            Cookies{' '}
          </Link>
          |{' '}
          <Link
            to={(location) => ({
              ...location,
              pathname: '/terminos-y-condiciones',
            })}
          >
            Términos y Condiciones
          </Link>
        </div>
        <div className="copyText">
          Copyright © 2020 Suprive Media S.L. Cursos Marcos Alberca. All rights
          reserved.
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="copyText">
          Esta web no pertenece a Facebook o Facebook Inc. y tampoco está
          respaldada por Facebook de ninguna manera. Facebook proporciona una
          plataforma para ofrecer publicidad, pero los contenidos ofrecidos en
          esta web no son representativos de Facebook Inc.
          <br />
          <br />
          This website does not belong to Facebook or Facebook Inc. and is not
          endorsed by Facebook in any way. Facebook provides a platform to offer
          advertising, but the content offered on this website is not
          representative of Facebook Inc.
        </div>
        <br />
        <br />
        <br />
        <br className="d-none d-lg-block" />
      </div>
    </Row>
  );
};
export default Footer;
