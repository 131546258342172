import React from 'react';
import { Row } from 'react-bootstrap';
import SectionCard from '../../components/sectionCard/SectionCard';
import './SectionSolutions.scss';

export const SectionSolutions = () => {
    return (
        <Row className="RootCard">
            <div className="RootCard-inside" style={{ zIndex: "99" }}>
                <SectionCard />
            </div>
        </Row >
    );
};

export default SectionSolutions;