import React from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';
import './JumbotronCourseAccess.scss';

export const JumbotronCourseAccess = () => {
  return (
    <Jumbotron
      className="jumbotronCourseAccess"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="page-hero d-flex align-items-center justify-content-center courseAccessContent">
        <div>
          <h1 className="courseAccessTitle">
            ¿Quieres llevar tus fotos al siguiente nivel?
          </h1>
          <div className="btnWrap">
            <Button
              onClick={() => {
                ScrollToSmooth(document.querySelector('#payment'));
              }}
              bsPrefix
              className="buttonCourseAccess enrollCourseAccess"
            >
              <span>¡Accede al curso!</span>
            </Button>
          </div>
        </div>
      </div>
    </Jumbotron>
  );
};

export default JumbotronCourseAccess;
