import React from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';
import './JumbotronAccess.scss';

export const JumbotronContent = () => {
  return (
    <Jumbotron className="jumbotronAccess">
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center" data-aos="fade-up">
            <h1 className="accessTitle">
              "El poder de capturar lo que sientes"
            </h1>

            <p className="accessP">
              Adquiere el conocimiento necesario para
              <br />
              empezar a crear material profesional.
            </p>

            <p className="buttonsGroup">
              <Button
                onClick={() => {
                  ScrollToSmooth(document.querySelector('#payment'));
                }}
                bsPrefix
                className="buttonAccess enrollAccess"
              >
                <span>¡Acceder ahora!</span>
              </Button>
            </p>
          </div>
        </div>
      </div>
    </Jumbotron>
  );
};

export default JumbotronContent;
