import React from "react";
import { Button } from "react-bootstrap";
import "./ArrowBack.scss";
import { Link } from "react-router-dom";

export const ArrowBack = (props) => {
  // Button HIDDEN ON SCROLL //
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("buttonBack").style.top = "-50px";
    } else {
      document.getElementById("buttonBack").style.top = "-127px";
    }
    prevScrollpos = currentScrollPos;
  };
  return (
    <div>
      <Link
        to={(location) => ({
          ...location,
          pathname: "/home",
        })}
      >
        <Button variant="primary" id="buttonBack" className="backButton">
          Atras
        </Button>
      </Link>
    </div>
  );
};

export default ArrowBack;
