import React from 'react';
import { Row } from 'react-bootstrap';
import { JumbotronCourseAccess } from '../../components/jumbotronCourseAccess/JumbotronCourseAccess';
import './CourseAccess.scss';

export const CourseAccess = (props) => {
    return (
        <Row className="RootCourseAccess" id="accessPage">
            <div className="RootCourseAccess-inside" style={{ zIndex: "99" }}>
                <JumbotronCourseAccess />
            </div>
        </Row >
    );
};
export default CourseAccess;
