import React from 'react';
import { isMobile } from '../../App';

const ScrollToSmooth = (target) => {
  window.scrollTo({
    top: target.offsetTop, // - 72 if navbar
    behavior: 'smooth',
  });
};

export default ScrollToSmooth;
