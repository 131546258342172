import React, { lazy, Suspense } from 'react';

import Layout, { isPromoActive, isExamenes } from '../layout/Layout';

import Home from './landing/pages/home/Home';
import BrandPage from './landing/pages/brandPage/BrandPage';
import AccessNow from './landing/pages/accessNow/AccessNow';
import SectionSolutions from './landing/pages/sectionSolutions/SectionSolutions';
import ImgSection from './landing/pages/imgSection/ImgSection';
import SectionCharact from './landing/pages/sectionCharact/SectionCharact';
import SectionSpecs from './landing/pages/sectionSpecs/SectionSpecs';
import JoinUs from './landing/pages/joinUs/JoinUs';
import GridContent from './landing/components/gridContent/GridContent';
import CourseAccess from './landing/pages/courseAccess/CourseAccess';
import SectionAccordion from './landing/pages/sectionAccordion/SectionAccordion';
import SectionArtist from './landing/pages/sectionArtist/SectionArtist';
import SectionFaq from './landing/pages/sectionFaq/SectionFaq';
import BottomAccess from './landing/pages/bottomAccess/BottomAccess';
import PaymentSection from './landing/pages/paymentSection/PaymentSection';
import Banner from '../components/banner/Banner';
// import Footer from "../components/footer/Footer";

import promoFixed from '../assets/imgs/promo/promo.svg';
import SectionTestimonials from './landing/pages/sectionTestimonials/SectionTestimonials';
import PaymentSectionTop from './landing/pages/paymentSectionTop/PaymentSection';

const Footer = lazy(() => import('../components/footer/Footer'));

export const App = (props) => {
  return (
    <Layout>
      {isPromoActive() && isExamenes ? (
        <img src={promoFixed} alt="" className="promoFixed" />
      ) : (
        ''
      )}
      <Banner />
      <Home />
      <PaymentSectionTop />
      <BrandPage />
      <AccessNow />
      <SectionSolutions />
      <ImgSection />
      <SectionCharact />
      <SectionSpecs />
      <JoinUs />
      <GridContent />
      {/* <SectionValors /> */}
      <CourseAccess />
      <SectionAccordion />
      <SectionArtist />
      {/* <SectionReviews /> */}
      <SectionTestimonials />
      <SectionFaq />
      <BottomAccess />
      <PaymentSection />
      <Suspense fallback={<div className="suspenseImg"></div>}>
        <Footer />
      </Suspense>
    </Layout>
  );
};

export default App;
