import React, { useEffect } from 'react';

import Router from './routes/Router';

// SASS Importing
import './App.scss';

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const App = (props) => {
  useEffect(() => {
    const onTidioChatApiReady = () => {
      window.tidioChatApi.setColorPalette('#FF5D5D');
    };

    let tidioChatReadyListener = null;

    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
    } else {
      tidioChatReadyListener = document.addEventListener(
        'tidioChat-ready',
        onTidioChatApiReady
      );
    }
    return () => {
      if (tidioChatReadyListener) {
        document.removeEventListener(tidioChatReadyListener);
      }
    };
  }, []);
  return (
    <>
      <Router />
    </>
  );
};

export default App;
