import React from 'react';
import { Jumbotron, Button } from 'react-bootstrap';
import ScrollToSmooth from '../../../../components/scrollToSmooth/ScrollToSmooth';
import './JumbotronBottomAccess.scss';

export const JumbotronBottomAccess = () => {
  return (
    <Jumbotron
      className="jumbotronBottomAccess"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center" id="sectionBottomAccess">
            <h1 className="bottomAccessTitle">¿A qué estas esperando?</h1>

            <p className="bottomAccessP">
              Aprende a expresarte más allá de la experiencia sensorial y a
              capturar lo que sientes.
            </p>

            <p className="buttonsGroup">
              <Button
                onClick={() => {
                  ScrollToSmooth(document.querySelector('#payment'));
                }}
                bsPrefix
                className="buttonBottomAccess enrollBottomAccess"
              >
                <p>¡Acceder ahora!</p>
              </Button>
            </p>
          </div>
        </div>
      </div>
    </Jumbotron>
  );
};

export default JumbotronBottomAccess;
