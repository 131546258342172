import React from "react";
import "./SectionAccordion.scss";
import AccordionContent from "../../components/accordionContent/AccordionContent";

export const SectionAccordion = () => {
  return (
    <div className="RootAccordion">
      <div className="fluid RootAccordion-inside">
        <div className="">
          <AccordionContent />
        </div>
      </div>
    </div>
  );
};

export default SectionAccordion;
