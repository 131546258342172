import React from 'react';
import { Jumbotron, Image } from 'react-bootstrap';
import './JumbotronBrand.scss';

export const JumbotronContent = () => {
  return (
    <Jumbotron className="jumbotronBrand">
      <div className="jumboBrandMore">
        <div className="brandFollowers">
          <h1 className="capitalLetterBrand">+4mill.</h1>
          <h2 className="titleTextBrand">
            de <br className="d-none d-lg-block" />
            seguidores
          </h2>
        </div>
        <div className="brandHours">
          <h1 className="capitalLetterBrand">+6000h</h1>
          <h2 className="titleTextBrand">
            haciendo <br className="d-none d-lg-block" />
            fotos
          </h2>
        </div>
      </div>

      <div className="hrLine"></div>
      <p className="cardTitle colaborationsText">
        Algunas colaboraciones de Marcos
      </p>
      <div className="containerBrands">
        <div>
          <Image
            className="imgsBrand"
            src={require('../../../../assets/imgs/brands/Samsung.svg')}
          />
        </div>
        <div>
          <Image
            className="imgsBrand"
            src={require('../../../../assets/imgs/brands/Starbucks.svg')}
          />
        </div>
        <div>
          <Image
            className="imgsBrand"
            src={require('../../../../assets/imgs/brands/Oppo.svg')}
          />
        </div>
        <div>
          <Image
            className="imgsBrand"
            src={require('../../../../assets/imgs/brands/Lg.svg')}
          />
        </div>
        <div>
          <Image
            className="imgsBrand"
            src={require('../../../../assets/imgs/brands/Canon.svg')}
          />
        </div>
      </div>
    </Jumbotron>
  );
};

export default JumbotronContent;
