import React from 'react';

export const CustomPrevArrow = (props) => {
  const { className, onClick, sliderArrowMouse } = props;
  return (
    <div className={`${className} ${sliderArrowMouse}`} onClick={onClick}>
      {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
      <div className="slickPrev link" onClick={onClick}>
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 8 8"
        >
          <path
            fill="#FFFFFF"
            className="st0"
            d="M5.3,0l-4,4l4,4l1.5-1.5L4.3,4l2.5-2.5L5.3,0z"
          />
        </svg>
      </div>
    </div>
  );
};

export default CustomPrevArrow;
